.modal {
    flex: 0 1 270px;
    padding: 10px;
    border: 1px solid rgb(204, 204, 204);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 4px;
    outline: none;
    overflow-y: auto;
    font-size: 24px;
}

.modalFull {
    flex: 1 1;
    width: 100%;
    height: 100%;
    padding: 0px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 4px;
    outline: none;
    overflow-y: auto;
    font-size: 24px;
}

.modalFullTop {
    flex: 1 1;
    width: 100%;
    height: 100%;
    padding: 0px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    border-radius: 4px;
    outline: none;
    overflow-y: auto;
    font-size: 24px;
}

.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    overflow-y: auto;
}

.modalContent {
    flex: 1 1;
}

.themeDark {
    color: white;
    background-color: gray;
}
