.image_menu {
    background-image: url('../../img/menu.png')
}

.image_infoDark {
    background-image: url('../../img/infoDark.png')
}

.image_infoRed {
    background-image: url('../../img/infoRed.png')
}

.image_infoYellow {
    background-image: url('../../img/infoYellow.png')
}

.image_logout {
    background-image: url('../../img/logout.png')
}

.image_ice {
    background-image: url('../../img/ice.png')
}

.image_players {
    background-image: url('../../img/players.png')
}

.image_playersDark {
    background-image: url('../../img/playersDark.png')
}

.image_cancelDark {
    background-image: url('../../img/cancelDark.png')
}

.image_cancelLight {
    background-image: url('../../img/cancelLight.png')
}

.image_cancelRed {
    background-image: url('../../img/cancelRed.png')
}

.image_okDark {
    background-image: url('../../img/okDark.png')
}

.image_okGreen {
    background-image: url('../../img/okGreen.png')
}

.image_okYellow {
    background-image: url('../../img/okYellow.png')
}

.image_arrowUp {
    background-image: url('../../img/arrowEmptyUp.png')
}

.image_arrowDown {
    background-image: url('../../img/arrowEmptyDown.png')
}

.image_automatic {
    background-image: url('../../img/automatic.png')
}

.image_automaticDark {
    background-image: url('../../img/automaticDark.png')
}

.image_simpleArrowUp {
    background-image: url('../../img/simpleArrowUp.png')
}

.image_simpleArrowDown {
    background-image: url('../../img/simpleArrowDown.png')
}

.image_flagUkraine {
    background-image: url('../../img/flag-ukraine-small.png')
}
