body {
    margin: 0px;
    font-family: Arial;
}

td {
    padding: 0px;
}

.selectable {  
    -webkit-user-select: all;  /* Chrome 49+ */
    -moz-user-select: all;     /* Firefox 43+ */
    -ms-user-select: all;      /* No support yet */
    user-select: all;          /* Likely future */   
}

.unselectable {  
    /* disable user text selection */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

div {
    box-sizing: border-box;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
}

.bgDark             { background-color: #4C4C4C }
.bgMedium           { background-color: #707070 }
.bgLight            { background-color: #D8D8D8 }
.bgLighter          { background-color: #E8E8E8 }
.bgWhite            { background-color: white }

.bgOn               { background-color: #33AF3F }
.bgMaybe            { background-color: #EADE00 }
.bgWarn             { background-color: #D86363 }
.bgOff              { background-color: #D8D8D8 }
.bgMarkDark         { background-color: #202080 }
.bgMarkLight        { background-color: #FFFFFF }
.bgNotice           { background-color: #FFFF00 }
.bgFilterActive     { background-color: #E8F0FF }

.bgBannerUkraine    { background-color: #B0FFFF }

.fgBlack            { color: black }
.fgDark             { color: #4C4C4C }
.fgMedium           { color: #707070 }
.fgLight            { color: #D0D0D0 }
.fgWhite            { color: white }
.fgFilterActive     { color: #2020A0 }

.fgOn               { color: #33AF3F }
.fgMaybe            { color: #b3aa00 }
.fgWarn             { color: #D86363 }
.fgBad              { color: #FF0000 }

.pad                { padding: 8px }
.padSmall           { padding: 3px }
.padTop             { padding-top: 8px }
.padTopSmall        { padding-top: 3px }
.padLeft            { padding-left: 8px }
.padLeftSmall       { padding-left: 3px }
.padRight           { padding-right: 8px }
.padRightSmall      { padding-right: 3px }
.padBottom          { padding-bottom: 8px }
.padBottomSmall     { padding-bottom: 3px }

.marg                { margin: 8px }
.margSmall           { margin: 3px }
.margTop             { margin-top: 8px }
.margTopSmall        { margin-top: 3px }
.margLeft            { margin-left: 8px }
.margLeftSmall       { margin-left: 3px }
.margRight           { margin-right: 8px }
.margRightSmall      { margin-right: 3px }
.margBottom          { margin-bottom: 8px }
.margBottomSmall     { margin-bottom: 3px }

.fMega              { font-size: 40px }
.fBigger            { font-size: 28px }
.fBig               { font-size: 22px }
.fMedium            { font-size: 18px }
.fNormal            { font-size: 16px }
.fSmall             { font-size: 14px }
.fSmaller           { font-size: 13px }
.fMini              { font-size: 10px }

.fBold              { font-weight: bold }
.fItalic            { font-style: italic }
.fUnderline         { text-decoration: underline; }

.imgProfileMega     { width: 150px; height: 150px }
.imgProfileBig      { width: 50px; height: 50px }
.imgProfileMedium   { width: 42px; height: 42px }

.icon               { background-repeat: no-repeat }
.iconBig            { width: 50px; height: 50px; background-size: 50px 50px }
.iconMedium         { width: 42px; height: 42px; background-size: 42px 42px }
.iconNormal         { width: 36px; height: 36px; background-size: 36px 36px }
.iconSmall          { width: 20px; height: 20px; background-size: 20px 20px }
.iconMini           { width: 16px; height: 16px; background-size: 16px 16px }

.hBiggest           { min-height: 105px }
.hBigger            { min-height: 80px }
.hBig               { min-height: 60px }

.bordBottom         { border-bottom: solid 1px #4C4C4C }
.bordSilver         { border: 1px solid silver; }
.bordFatBlack       { border: 2px solid black; }

.zIndexBack         { z-index: -1; }

.clickable          { cursor: pointer }

.userBackground {
    z-index: 1;
}

.user {
    border-bottom: 2px solid #4C4C4C;
}

.modalBase {
    z-index: 2;
}

.mainMenuOverlay {
    position: fixed;
    height: 100%;
    z-index: 3;
}

.mainMenu {
    z-index: 3;
}

.mainMenu a:link, .mainMenu a:visited, .mainMenu a:hover, .mainMenu a:active {
    color: white;
    text-decoration: none;
}

.mainMenuItem {
    white-space: nowrap;
}

.fixedDown {
    position: fixed; 
    left: 0px;
    bottom: 0px;
    height: 60px;
}

.container {
    width: 100%;
    max-width: 500px;
}
